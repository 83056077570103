import Link from "next/link";
import { useRouter } from "next/router";

export default function NavItem({ href, text }) {
  const router = useRouter();
  const isActive = router.asPath === href;

  return (
    <Link href={href}>
      <a
        className={`${
          isActive
            ? "dark:text-blau text-gold underline underline-offset-2"
            : "dark:text-blauHover"
        } block md:inline-block p-1 sm:px-3 py-3 sm:py-2 mb-3 rounded-lg text-blau hover:bg-blau hover:text-white dark:hover:text-gold dark:text-blauHover  transition-all`}
      >
        <span className="">{text}</span>
      </a>
    </Link>
  );
}
