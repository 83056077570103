import { useRouter } from "next/router";
import React, { useState, useEffect, useCallback } from "react";
import NavItem from "./NavItem";
import ThemeChanger from "./ThemeChanger";
import de from "../locales/de";
import en from "../locales/en";
import Footer from "./Footer";
import { useTheme } from "next-themes";

export default function Container(props) {
  const { children } = props;

  const enFlag = "EN"; // 🇺🇸
  const deFlag = "DE"; //

  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : de;

  const changeLanguage = (e) => {
    const locale = e.target.value;
    router.push(router.pathname, router.asPath, { locale });
    // router.reload(window.location.pathname);
  };
  const changeMobileLanguage = (e) => {
    const locale = e.target.value;
    router.push(router.pathname, router.asPath, { locale });
    // router.reload(window.location.pathname);
    toggleNav();
  };
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleNav = useCallback(() => {
    setMenuOpen((v) => !v);
  }, []);

  // ThemeChanger
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  // When mounted on client, now we can show the UI
  useEffect(() => setMounted(true), []);
  const switchMobileTheme = () => {
    if (mounted) {
      setTheme(theme === "light" ? "dark" : "light");
    }
    toggleNav();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="bg-hellGrau dark:bg-gray-900">
      <nav className="w-full fixed top-0 text-center bg-gold z-50 visible md:hidden">
        <button
          className="absolute w-8 h-8 text-blau dark:bg-gold dark:text-white p-2 rounded right-6 top-6"
          onClick={() => toggleNav()}
        >
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>

        <div
          className={
            menuOpen ? "h-screen pt-32 bg-hellGrau dark:bg-gray-800" : "hidden"
          }
        >
          <div className="grid grid-cols-1 text-2xl">
            <NavItem href="/" text="Home" />
            <NavItem href="/gssp" text="GSSP" />

            <select
              onChange={changeMobileLanguage}
              defaultValue={locale}
              className="w-14 h-10 text-blau text-shadow-sm text-md bg-transparent tracking-wide mx-auto"
            >
              <option className="" value="en">
                {enFlag}
              </option>
              <option className="" value="de">
                {deFlag}
              </option>
            </select>

            {/* Mobile Theme Changer */}
            <div className="justify-between items-center font-bold text-xl mx-auto md:inline-block translate-y-1.5 mt-20">
              <button
                className="w-full md:w-6 text-blau dark:text-blauHover"
                onClick={switchMobileTheme}
              >
                {theme === "dark" ? (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                ) : (
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    id="moon"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                    ></path>
                  </svg>
                )}
              </button>
            </div>

            <a href="https://www.centropa.org" className="block mt-10 mx-auto">
              <svg
                width="148"
                height="32"
                viewBox="0 0 148 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M41.0003 4.32275C42.7917 4.32275 46.0243 5.05101 46.0243 7.42285V9.03972H42.987V8.28473C42.987 7.52975 41.8421 7.20905 41.0003 7.20905C38.6095 7.20905 36.8989 9.01299 36.8989 11.4917C36.8756 12.0579 36.9717 12.6226 37.1811 13.1498C37.3905 13.6769 37.7086 14.1549 38.115 14.553C38.5214 14.9512 39.0071 15.2607 39.5411 15.4618C40.075 15.6629 40.6455 15.7512 41.2158 15.721C42.7681 15.629 44.2302 14.9669 45.3172 13.8636L46.7584 16.2354C46.0099 17.0251 45.1047 17.6524 44.0998 18.0778C43.0948 18.5033 42.012 18.7177 40.9195 18.7075C36.3669 18.7075 33.4238 15.5072 33.4238 11.5185C33.4238 7.63665 36.2456 4.32275 41.0003 4.32275Z"
                  fill="#0070D1"
                />
                <path
                  d="M55.0691 4.32275C59.0358 4.32275 61.2043 7.20905 61.2043 10.8437C61.2043 11.2512 61.1235 12.1131 61.1235 12.1131H51.5671C51.6331 13.1459 52.1058 14.1115 52.8832 14.8015C53.6607 15.4915 54.6804 15.8506 55.7224 15.8011C57.1563 15.7432 58.5273 15.201 59.6082 14.2645L61.0427 16.6363C59.5045 17.9756 57.5265 18.712 55.4799 18.7075C50.8869 18.7075 48.0718 15.4203 48.0718 11.5118C48.0382 7.28923 50.9138 4.32275 55.0691 4.32275ZM57.6754 9.74125C57.6898 9.38859 57.6337 9.0366 57.5102 8.70559C57.3867 8.37458 57.1984 8.07111 56.9561 7.8127C56.7138 7.55428 56.4223 7.34605 56.0984 7.20001C55.7745 7.05397 55.4247 6.97303 55.0691 6.96185C54.2634 6.94073 53.4775 7.21108 52.8579 7.72248C52.2383 8.23389 51.8274 8.95142 51.7018 9.74125H57.6754Z"
                  fill="#0070D1"
                />
                <path
                  d="M64.5445 8.15118C64.5554 8.07112 64.5477 7.98965 64.5219 7.91303C64.4961 7.83641 64.4529 7.76669 64.3956 7.70925C64.3383 7.6518 64.2686 7.60816 64.1916 7.58169C64.1147 7.55521 64.0327 7.54661 63.9518 7.55655H62.8608V4.67693H66.0396C67.2653 4.67693 67.8579 5.23815 67.8579 6.20693V6.61449C67.8553 6.81377 67.8373 7.01256 67.8041 7.20912H67.8579C68.3136 6.32084 69.0132 5.57851 69.8759 5.0678C70.7387 4.55709 71.7294 4.29883 72.7338 4.32282C75.8048 4.32282 77.5692 5.91296 77.5692 9.55424V14.8992C77.5608 14.9794 77.5705 15.0604 77.5976 15.1364C77.6247 15.2123 77.6686 15.2813 77.7261 15.3383C77.7835 15.3953 77.853 15.4388 77.9296 15.4657C78.0062 15.4927 78.0878 15.5023 78.1686 15.4939H79.2529V18.3735H75.9664C74.6666 18.3735 74.1211 17.839 74.1211 16.5428V10.2491C74.1211 8.57878 73.6834 7.44965 71.9458 7.44965C71.1003 7.43815 70.2751 7.70743 69.6015 8.21468C68.9279 8.72192 68.4446 9.43801 68.2283 10.2491C68.0387 10.8432 67.9455 11.4633 67.9522 12.0864V18.3869H64.5445V8.15118Z"
                  fill="#0070D1"
                />
                <path
                  d="M81.5225 7.34926H79.7244V4.67676H81.6033V0.901855H84.9706V4.67676H88.1224V7.34926H84.9706V12.9014C84.9706 15.1596 86.762 15.487 87.7116 15.487C87.9217 15.4898 88.1315 15.4719 88.3379 15.4336V18.4401C87.9888 18.5002 87.635 18.5293 87.2806 18.527C85.3478 18.527 81.5225 17.9591 81.5225 13.349V7.34926Z"
                  fill="#0070D1"
                />
                <path
                  d="M91.5167 8.15092C91.5278 8.07023 91.52 7.98808 91.4938 7.91089C91.4676 7.8337 91.4238 7.76357 91.3658 7.70599C91.3077 7.6484 91.237 7.60492 91.1592 7.57895C91.0814 7.55298 90.9986 7.54522 90.9173 7.55628H89.833V4.67667H92.9781C94.2038 4.67667 94.8301 5.18444 94.8301 6.34698V7.15541C94.8318 7.44358 94.8138 7.73153 94.7762 8.01729H94.8301C95.0846 7.03843 95.6494 6.16658 96.4411 5.53024C97.2329 4.89391 98.2098 4.52676 99.2278 4.48291C99.4376 4.48639 99.6468 4.50425 99.8541 4.53636V7.88367C99.5755 7.84253 99.294 7.82243 99.0123 7.82353C98.1526 7.82319 97.3168 8.10373 96.6339 8.6218C95.951 9.13987 95.4591 9.86663 95.2342 10.6898C95.0343 11.4192 94.9346 12.1721 94.9379 12.928V18.3665H91.5167V8.15092Z"
                  fill="#0070D1"
                />
                <path
                  d="M108.38 4.32275C112.616 4.32275 115.984 7.31595 115.984 11.5185C115.984 15.721 112.616 18.7075 108.38 18.7075C104.144 18.7075 100.804 15.741 100.804 11.5118C100.804 7.28255 104.171 4.32275 108.38 4.32275ZM108.38 15.8011C108.939 15.8027 109.492 15.6915 110.006 15.4744C110.52 15.2573 110.984 14.9388 111.37 14.5383C111.756 14.1377 112.056 13.6635 112.252 13.1444C112.448 12.6252 112.535 12.0721 112.508 11.5185C112.541 10.9627 112.458 10.4063 112.266 9.88342C112.073 9.36049 111.775 8.88202 111.39 8.47732C111.005 8.07263 110.54 7.75023 110.025 7.52988C109.51 7.30954 108.955 7.19588 108.394 7.19588C107.833 7.19588 107.277 7.30954 106.762 7.52988C106.247 7.75023 105.782 8.07263 105.397 8.47732C105.012 8.88202 104.714 9.36049 104.522 9.88342C104.329 10.4063 104.247 10.9627 104.279 11.5185C104.251 12.07 104.337 12.6214 104.531 13.1392C104.725 13.6569 105.022 14.1303 105.406 14.5307C105.789 14.931 106.25 15.2501 106.762 15.4684C107.273 15.6868 107.823 15.8 108.38 15.8011Z"
                  fill="#0070D1"
                />
                <path
                  d="M118.832 8.15122C118.844 8.07053 118.836 7.98838 118.81 7.91119C118.783 7.834 118.74 7.76387 118.681 7.70629C118.623 7.6487 118.553 7.60522 118.475 7.57925C118.397 7.55328 118.314 7.54552 118.233 7.55659H117.182V4.67697H120.253C121.526 4.67697 121.991 5.23819 121.991 6.01322V6.5544H122.045C122.045 6.5544 123.21 4.36963 126.442 4.36963C130.194 4.36963 132.611 7.30938 132.611 11.5653C132.611 15.9282 129.918 18.761 126.254 18.761C125.497 18.7893 124.743 18.6408 124.054 18.3275C123.365 18.0142 122.76 17.5448 122.287 16.9571H122.233C122.233 16.9571 122.287 17.4382 122.287 18.1397V23.8254H118.866L118.832 8.15122ZM125.567 15.828C127.473 15.828 129.076 14.2913 129.076 11.572C129.076 8.95965 127.661 7.26261 125.621 7.26261C123.829 7.26261 122.146 8.55209 122.146 11.5987C122.173 13.7301 123.318 15.828 125.594 15.828H125.567Z"
                  fill="#0070D1"
                />
                <path
                  d="M142.437 9.76797H142.868V9.66107C142.868 7.6567 142.08 6.98857 140.316 6.98857C139.69 6.98857 138.471 7.1556 138.471 7.91058V8.63216H135.265V7.1556C135.265 4.59669 138.902 4.32275 140.343 4.32275C144.963 4.32275 146.316 6.72132 146.316 9.7947V14.9125C146.308 14.9927 146.318 15.0737 146.345 15.1497C146.372 15.2256 146.416 15.2946 146.473 15.3516C146.531 15.4086 146.6 15.4521 146.677 15.479C146.753 15.506 146.835 15.5156 146.916 15.5072H148V18.3868H144.909C143.629 18.3868 143.138 17.7187 143.138 16.8835C143.138 16.5294 143.138 16.2889 143.138 16.2889H143.084C142.693 17.0408 142.095 17.6673 141.359 18.0954C140.624 18.5236 139.781 18.7358 138.929 18.7075C136.457 18.7075 134.214 17.1708 134.214 14.4248C134.208 10.1154 140.181 9.76797 142.437 9.76797ZM139.743 16.0417C141.596 16.0417 142.895 14.1041 142.895 12.4338V12.1131H142.296C140.504 12.1131 137.656 12.3536 137.656 14.2645C137.683 15.1798 138.363 16.035 139.77 16.035L139.743 16.0417Z"
                  fill="#0070D1"
                />
                <path
                  d="M23.4769 6.51253e-06H3.45486C2.53857 6.51253e-06 1.65982 0.361115 1.01191 1.00389C0.363993 1.64667 0 2.51846 0 3.42749L0 19.5026C0 20.4116 0.363993 21.2834 1.01191 21.9262C1.65982 22.5689 2.53857 22.9301 3.45486 22.9301H8.84256C10.277 27.5401 13.1325 30.2794 19.2004 31.6157C19.3437 31.6355 19.4897 31.609 19.6167 31.5401C19.7436 31.4712 19.8448 31.3635 19.9053 31.2331C19.9657 31.1026 19.9822 30.9563 19.9523 30.8158C19.9224 30.6754 19.8477 30.5482 19.7392 30.4531C18.6984 29.5209 17.9111 28.3437 17.4497 27.0301C16.9883 25.7165 16.8678 24.3087 17.0992 22.9367H23.4769C23.9317 22.9376 24.3823 22.8494 24.8026 22.6771C25.223 22.5049 25.605 22.2519 25.9266 21.9329C26.2482 21.6138 26.5031 21.2349 26.6768 20.8178C26.8504 20.4008 26.9394 19.9538 26.9385 19.5026V3.42749C26.9385 2.97682 26.8489 2.53058 26.6749 2.1143C26.5008 1.69803 26.2457 1.31989 25.9242 1.00153C25.6027 0.683169 25.221 0.430842 24.8011 0.258988C24.3811 0.0871342 23.9312 -0.000871979 23.4769 6.51253e-06ZM13.7386 18.1262C9.53622 18.1262 6.83564 15.1798 6.83564 11.5051C6.83564 7.93065 9.4352 4.884 13.8127 4.884C15.4627 4.884 18.4394 5.55212 18.4394 7.73689V9.22013H15.6378V8.55201C15.6378 7.88388 14.5872 7.56318 13.8127 7.56318C13.2986 7.55862 12.789 7.65897 12.3155 7.858C11.8421 8.05702 11.415 8.35048 11.0607 8.72014C10.7064 9.0898 10.4325 9.52777 10.256 10.0069C10.0795 10.4859 10.0041 10.9959 10.0346 11.5051C10.0169 12.0244 10.1085 12.5417 10.3034 13.0239C10.4984 13.5062 10.7924 13.9428 11.1669 14.3061C11.5413 14.6694 11.9879 14.9514 12.4782 15.134C12.9685 15.3166 13.4918 15.3959 14.0147 15.3669C15.4444 15.2816 16.791 14.6719 17.7929 13.6565L19.1398 15.8412C18.4482 16.5726 17.611 17.1534 16.6812 17.5468C15.7514 17.9401 14.7494 18.1375 13.7386 18.1262Z"
                  fill="#0070D1"
                />
              </svg>
            </a>
          </div>
        </div>
      </nav>

      {/* Desktop */}
      <nav className="pt-6 pb-8 hidden md:block md:fixed w-full h-22 text-right pr-10 bg-hellGrau z-40 dark:bg-gray-900">
        <NavItem href="/" text="Home" />
        <NavItem href="/gssp" text="GSSP" />

        <select
          onChange={changeLanguage}
          defaultValue={locale}
          className="w-16 text-right text-blau text-shadow-sm text-lg bg-transparent tracking-wide mr-10"
        >
          <option className="text-blau w-20" value="en">
            {enFlag}
          </option>
          <option className="text-blau" value="de">
            {deFlag}
          </option>
        </select>
        <ThemeChanger />
        <a
          className="inline-block w-36 translate-y-3.5 ml-12"
          href="https://www.centropa.org"
        >
          <svg
            width="130"
            height="32"
            viewBox="0 0 148 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41.0003 4.32275C42.7917 4.32275 46.0243 5.05101 46.0243 7.42285V9.03972H42.987V8.28473C42.987 7.52975 41.8421 7.20905 41.0003 7.20905C38.6095 7.20905 36.8989 9.01299 36.8989 11.4917C36.8756 12.0579 36.9717 12.6226 37.1811 13.1498C37.3905 13.6769 37.7086 14.1549 38.115 14.553C38.5214 14.9512 39.0071 15.2607 39.5411 15.4618C40.075 15.6629 40.6455 15.7512 41.2158 15.721C42.7681 15.629 44.2302 14.9669 45.3172 13.8636L46.7584 16.2354C46.0099 17.0251 45.1047 17.6524 44.0998 18.0778C43.0948 18.5033 42.012 18.7177 40.9195 18.7075C36.3669 18.7075 33.4238 15.5072 33.4238 11.5185C33.4238 7.63665 36.2456 4.32275 41.0003 4.32275Z"
              fill="#0070D1"
            />
            <path
              d="M55.0691 4.32275C59.0358 4.32275 61.2043 7.20905 61.2043 10.8437C61.2043 11.2512 61.1235 12.1131 61.1235 12.1131H51.5671C51.6331 13.1459 52.1058 14.1115 52.8832 14.8015C53.6607 15.4915 54.6804 15.8506 55.7224 15.8011C57.1563 15.7432 58.5273 15.201 59.6082 14.2645L61.0427 16.6363C59.5045 17.9756 57.5265 18.712 55.4799 18.7075C50.8869 18.7075 48.0718 15.4203 48.0718 11.5118C48.0382 7.28923 50.9138 4.32275 55.0691 4.32275ZM57.6754 9.74125C57.6898 9.38859 57.6337 9.0366 57.5102 8.70559C57.3867 8.37458 57.1984 8.07111 56.9561 7.8127C56.7138 7.55428 56.4223 7.34605 56.0984 7.20001C55.7745 7.05397 55.4247 6.97303 55.0691 6.96185C54.2634 6.94073 53.4775 7.21108 52.8579 7.72248C52.2383 8.23389 51.8274 8.95142 51.7018 9.74125H57.6754Z"
              fill="#0070D1"
            />
            <path
              d="M64.5445 8.15118C64.5554 8.07112 64.5477 7.98965 64.5219 7.91303C64.4961 7.83641 64.4529 7.76669 64.3956 7.70925C64.3383 7.6518 64.2686 7.60816 64.1916 7.58169C64.1147 7.55521 64.0327 7.54661 63.9518 7.55655H62.8608V4.67693H66.0396C67.2653 4.67693 67.8579 5.23815 67.8579 6.20693V6.61449C67.8553 6.81377 67.8373 7.01256 67.8041 7.20912H67.8579C68.3136 6.32084 69.0132 5.57851 69.8759 5.0678C70.7387 4.55709 71.7294 4.29883 72.7338 4.32282C75.8048 4.32282 77.5692 5.91296 77.5692 9.55424V14.8992C77.5608 14.9794 77.5705 15.0604 77.5976 15.1364C77.6247 15.2123 77.6686 15.2813 77.7261 15.3383C77.7835 15.3953 77.853 15.4388 77.9296 15.4657C78.0062 15.4927 78.0878 15.5023 78.1686 15.4939H79.2529V18.3735H75.9664C74.6666 18.3735 74.1211 17.839 74.1211 16.5428V10.2491C74.1211 8.57878 73.6834 7.44965 71.9458 7.44965C71.1003 7.43815 70.2751 7.70743 69.6015 8.21468C68.9279 8.72192 68.4446 9.43801 68.2283 10.2491C68.0387 10.8432 67.9455 11.4633 67.9522 12.0864V18.3869H64.5445V8.15118Z"
              fill="#0070D1"
            />
            <path
              d="M81.5225 7.34926H79.7244V4.67676H81.6033V0.901855H84.9706V4.67676H88.1224V7.34926H84.9706V12.9014C84.9706 15.1596 86.762 15.487 87.7116 15.487C87.9217 15.4898 88.1315 15.4719 88.3379 15.4336V18.4401C87.9888 18.5002 87.635 18.5293 87.2806 18.527C85.3478 18.527 81.5225 17.9591 81.5225 13.349V7.34926Z"
              fill="#0070D1"
            />
            <path
              d="M91.5167 8.15092C91.5278 8.07023 91.52 7.98808 91.4938 7.91089C91.4676 7.8337 91.4238 7.76357 91.3658 7.70599C91.3077 7.6484 91.237 7.60492 91.1592 7.57895C91.0814 7.55298 90.9986 7.54522 90.9173 7.55628H89.833V4.67667H92.9781C94.2038 4.67667 94.8301 5.18444 94.8301 6.34698V7.15541C94.8318 7.44358 94.8138 7.73153 94.7762 8.01729H94.8301C95.0846 7.03843 95.6494 6.16658 96.4411 5.53024C97.2329 4.89391 98.2098 4.52676 99.2278 4.48291C99.4376 4.48639 99.6468 4.50425 99.8541 4.53636V7.88367C99.5755 7.84253 99.294 7.82243 99.0123 7.82353C98.1526 7.82319 97.3168 8.10373 96.6339 8.6218C95.951 9.13987 95.4591 9.86663 95.2342 10.6898C95.0343 11.4192 94.9346 12.1721 94.9379 12.928V18.3665H91.5167V8.15092Z"
              fill="#0070D1"
            />
            <path
              d="M108.38 4.32275C112.616 4.32275 115.984 7.31595 115.984 11.5185C115.984 15.721 112.616 18.7075 108.38 18.7075C104.144 18.7075 100.804 15.741 100.804 11.5118C100.804 7.28255 104.171 4.32275 108.38 4.32275ZM108.38 15.8011C108.939 15.8027 109.492 15.6915 110.006 15.4744C110.52 15.2573 110.984 14.9388 111.37 14.5383C111.756 14.1377 112.056 13.6635 112.252 13.1444C112.448 12.6252 112.535 12.0721 112.508 11.5185C112.541 10.9627 112.458 10.4063 112.266 9.88342C112.073 9.36049 111.775 8.88202 111.39 8.47732C111.005 8.07263 110.54 7.75023 110.025 7.52988C109.51 7.30954 108.955 7.19588 108.394 7.19588C107.833 7.19588 107.277 7.30954 106.762 7.52988C106.247 7.75023 105.782 8.07263 105.397 8.47732C105.012 8.88202 104.714 9.36049 104.522 9.88342C104.329 10.4063 104.247 10.9627 104.279 11.5185C104.251 12.07 104.337 12.6214 104.531 13.1392C104.725 13.6569 105.022 14.1303 105.406 14.5307C105.789 14.931 106.25 15.2501 106.762 15.4684C107.273 15.6868 107.823 15.8 108.38 15.8011Z"
              fill="#0070D1"
            />
            <path
              d="M118.832 8.15122C118.844 8.07053 118.836 7.98838 118.81 7.91119C118.783 7.834 118.74 7.76387 118.681 7.70629C118.623 7.6487 118.553 7.60522 118.475 7.57925C118.397 7.55328 118.314 7.54552 118.233 7.55659H117.182V4.67697H120.253C121.526 4.67697 121.991 5.23819 121.991 6.01322V6.5544H122.045C122.045 6.5544 123.21 4.36963 126.442 4.36963C130.194 4.36963 132.611 7.30938 132.611 11.5653C132.611 15.9282 129.918 18.761 126.254 18.761C125.497 18.7893 124.743 18.6408 124.054 18.3275C123.365 18.0142 122.76 17.5448 122.287 16.9571H122.233C122.233 16.9571 122.287 17.4382 122.287 18.1397V23.8254H118.866L118.832 8.15122ZM125.567 15.828C127.473 15.828 129.076 14.2913 129.076 11.572C129.076 8.95965 127.661 7.26261 125.621 7.26261C123.829 7.26261 122.146 8.55209 122.146 11.5987C122.173 13.7301 123.318 15.828 125.594 15.828H125.567Z"
              fill="#0070D1"
            />
            <path
              d="M142.437 9.76797H142.868V9.66107C142.868 7.6567 142.08 6.98857 140.316 6.98857C139.69 6.98857 138.471 7.1556 138.471 7.91058V8.63216H135.265V7.1556C135.265 4.59669 138.902 4.32275 140.343 4.32275C144.963 4.32275 146.316 6.72132 146.316 9.7947V14.9125C146.308 14.9927 146.318 15.0737 146.345 15.1497C146.372 15.2256 146.416 15.2946 146.473 15.3516C146.531 15.4086 146.6 15.4521 146.677 15.479C146.753 15.506 146.835 15.5156 146.916 15.5072H148V18.3868H144.909C143.629 18.3868 143.138 17.7187 143.138 16.8835C143.138 16.5294 143.138 16.2889 143.138 16.2889H143.084C142.693 17.0408 142.095 17.6673 141.359 18.0954C140.624 18.5236 139.781 18.7358 138.929 18.7075C136.457 18.7075 134.214 17.1708 134.214 14.4248C134.208 10.1154 140.181 9.76797 142.437 9.76797ZM139.743 16.0417C141.596 16.0417 142.895 14.1041 142.895 12.4338V12.1131H142.296C140.504 12.1131 137.656 12.3536 137.656 14.2645C137.683 15.1798 138.363 16.035 139.77 16.035L139.743 16.0417Z"
              fill="#0070D1"
            />
            <path
              d="M23.4769 6.51253e-06H3.45486C2.53857 6.51253e-06 1.65982 0.361115 1.01191 1.00389C0.363993 1.64667 0 2.51846 0 3.42749L0 19.5026C0 20.4116 0.363993 21.2834 1.01191 21.9262C1.65982 22.5689 2.53857 22.9301 3.45486 22.9301H8.84256C10.277 27.5401 13.1325 30.2794 19.2004 31.6157C19.3437 31.6355 19.4897 31.609 19.6167 31.5401C19.7436 31.4712 19.8448 31.3635 19.9053 31.2331C19.9657 31.1026 19.9822 30.9563 19.9523 30.8158C19.9224 30.6754 19.8477 30.5482 19.7392 30.4531C18.6984 29.5209 17.9111 28.3437 17.4497 27.0301C16.9883 25.7165 16.8678 24.3087 17.0992 22.9367H23.4769C23.9317 22.9376 24.3823 22.8494 24.8026 22.6771C25.223 22.5049 25.605 22.2519 25.9266 21.9329C26.2482 21.6138 26.5031 21.2349 26.6768 20.8178C26.8504 20.4008 26.9394 19.9538 26.9385 19.5026V3.42749C26.9385 2.97682 26.8489 2.53058 26.6749 2.1143C26.5008 1.69803 26.2457 1.31989 25.9242 1.00153C25.6027 0.683169 25.221 0.430842 24.8011 0.258988C24.3811 0.0871342 23.9312 -0.000871979 23.4769 6.51253e-06ZM13.7386 18.1262C9.53622 18.1262 6.83564 15.1798 6.83564 11.5051C6.83564 7.93065 9.4352 4.884 13.8127 4.884C15.4627 4.884 18.4394 5.55212 18.4394 7.73689V9.22013H15.6378V8.55201C15.6378 7.88388 14.5872 7.56318 13.8127 7.56318C13.2986 7.55862 12.789 7.65897 12.3155 7.858C11.8421 8.05702 11.415 8.35048 11.0607 8.72014C10.7064 9.0898 10.4325 9.52777 10.256 10.0069C10.0795 10.4859 10.0041 10.9959 10.0346 11.5051C10.0169 12.0244 10.1085 12.5417 10.3034 13.0239C10.4984 13.5062 10.7924 13.9428 11.1669 14.3061C11.5413 14.6694 11.9879 14.9514 12.4782 15.134C12.9685 15.3166 13.4918 15.3959 14.0147 15.3669C15.4444 15.2816 16.791 14.6719 17.7929 13.6565L19.1398 15.8412C18.4482 16.5726 17.611 17.1534 16.6812 17.5468C15.7514 17.9401 14.7494 18.1375 13.7386 18.1262Z"
              fill="#0070D1"
            />
          </svg>
        </a>
      </nav>

      {/* Main Content */}
      <main className="flex flex-col justify-center pb-12 px-12 min-h-full max-w-6xl mx-auto overflow-y-auto dark:bg-gray-800">
        {children}
      </main>

      <button
        onClick={scrollToTop}
        className="text-xl text-blauHover h-8 w-8 rounded-lg bg-goldHover fixed bottom-6 right-6"
      >
        ^
      </button>
      <Footer />
    </div>
  );
}
